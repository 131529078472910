.default-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  background-image: url("../../../public/pictures/login_screen.jpg");
  background-color: var(--color-primary);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  height: fit-content;
  overflow: auto;
  position: relative;

  .background-overlay {
    content: "";
    background-color: rgba(
      255,
      255,
      255,
      0.2
    ); /* Adjust the opacity here (0.2 for 20%) */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .page {
    width: var(--page-width);
    align-items: center;
    position: relative;

    @media (max-width: 1120px) {
      width: 100%;
    }
  }
}
