.login-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    color: white;
    border-color: white;
  }
}

.login-modal-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .login-modal-ok-button {
    margin-top: 15px;
    button {
      border-radius: 16px;
    }
  }
}

.input-background {
  background-color: rgba(245, 245, 245, 0.35);
  border: 1px solid transparent;
  border-radius: 20px;
  margin-bottom: 10px;
  height: 60px;
}
