.table {
  border-radius: 40px !important;
  margin-bottom: 32px;
  padding: 10px !important;
  font-family: var(--font-primary) !important;
  min-width: fit-content !important;

  .table-columns {
    font-family: var(--font-primary) !important;
  }
}
