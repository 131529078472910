.header {
  width: 100vw;
  z-index: 2;

  .pages-buttons {
    border-radius: 20px;
    margin-right: 10px;
    padding: 10px;

    &:hover {
      background-color: var(--color-light-grey);
    }
    &.active {
      background-color: var(--color-light-grey);
    }
  }
}
