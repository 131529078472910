.login-layout {
  background-image: url("../../../public/pictures/login_screen.jpg");
  background-color: var(--color-primary);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;

  .background-overlay {
    content: "";
    background-color: rgba(
      255,
      255,
      255,
      0.2
    ); /* Adjust the opacity here (0.2 for 20%) */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .forgot-password-link {
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    font-family: var(--font-primary);
    &:hover {
      color: var(--color-highlight);
    }
  }
}
