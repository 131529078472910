.input-background {
  background-color: rgba(245, 245, 245, 0.35);
  border: 1px solid transparent;
  border-radius: 20px;
  margin-bottom: 10px;
  height: 60px;
}

h1 {
  color: white;
}
