.mutate-ticket-page {
  .data-creation-container {
    background-color: var(--color-gray-shader);
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 64px - 64px);
  }
  .stepper {
    margin-top: 15px;
  }
}
