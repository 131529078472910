.eye-icon {
  cursor: pointer;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

.delete-icon {
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
