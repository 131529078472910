.popup-message {
  padding: 15px;
}

.popup-buttons {
  position: relative;
  .button {
    float: right;
    margin-right: 15px !important;
  }
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  border-radius: 20px !important;
}
